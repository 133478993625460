.hide {
  display: none !important;
}
/*Nav Style*/
/* hide .only-m on desktop */
@media only screen and (min-width: 768px) {
  .only-m {
    display: none !important;
  }
}

/* show .only-m on mobile */
@media only screen and (max-width: 767px) {
  .only-m {
    display: block;
  }
}

/*End Nav Style*/
/*List item*/
.w-200 {
  width: 200px !important;
}
.w-250 {
  max-width: 250px !important;
}
/*Course Detail*/
.no-link {
  text-decoration: none;
  color: #FFF;
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 30%;
  float: left;
  margin-left: 20px;
  margin-right: 20px;
}
.text {
    text-align: justify;
}
.courseNav {
    height: 70px;
    background-color: #c0d735;
    color: white;
}
.courseNav i {
    height: 50px;
}
.cardBtn {
    border-radius: 10px;
    width: 120px;
    color: white;
    border: none;
    background-color: #ec711e;
    height: 30px;
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    text-align: center;
    padding: 1%;
}
.cardBtn:hover {
  font-weight: bold;
  color: #FFF;
}
.cardBtn2 {
    font-size: 11px;
    border-radius: 15px;
    width: 170px;
    height: 30px;
    color: #ec711e;
    border-color: #ec711e;
    background: none;
}

@media screen and (max-width: 768px) {
    .navText {
      font-size: 11px;
      text-align: center;
    }
    .courseNav {
        padding-left: 0%;
    }
    .card {
        margin: 0 auto;
    }
}
/*Course detail*/
/*Course*/
.course-img {
    width: 350px;
    height: 200px;
    border-radius: 5px;
}
.text {
    text-align: justify;
}
.courseNav {
    height: 70px;
    background-color: #c0d735;
    color: white;
}
.courseNav i {
    height: 50px;
}
.cardBtn {
    border-radius: 10px;
    width: 120px;
    color: white;
    border: none;
    background-color: #ec711e;
    height: 30px;
}
.cardBtn2 {
    border-radius: 15px;
    width: 170px;
    height: 30px;
    color: #ec711e;
    border-color: #ec711e;
    background: none;
}

@media screen and (max-width: 768px) {
    .navText {
        font-size: 15px;
    }
    .courseNav {
        padding-left: 0%;
    }
    .card {
        margin: 0 auto;
    }
}
/*End Course*/
/*Event*/
.regBtn {
    width: 150px;
    height: 40px;
    background-color: #ec711e;
    border-radius: 25px;
    border: none;
    color: white;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    padding: 5%;
}
.regBtn:hover{
  color: #FFF;
  text-decoration: none;
  font-weight: bold;
}
.shareBtn {
    width: 80px;
    height: 40px;
    background-color: inherit;
    border-color: #ec711e !important;
    border-radius: 25px;
    color: #ec711e;
}
.facebook-share-button .btn {
  background-color: inherit;
  border-color: #0866ff !important;
  border-radius: 25px;
  color: #0866ff;
  font-weight: 400 !important;
}
.facebook-share-button .btn:hover {
  background-color: #0866ff;
  color: #FFF;
}
.event-img {
    width: 350px;
    height: 350px;
}
/*End Event*/
/*For all items*/
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/*for all items*/
/*For Blogs*/
/* For mobile devices */
.small-slider {
  height:400px;
  object-fit: cover;
  filter:blur(0.1rem);
}
.post-img {
  width:auto;
}
@media only screen and (max-width: 767px) {
  .carousel-item {
    height: 400px;
  }
.post-img {
  width: 100% !important;
  }
}

.post-container {
  width: 250px; /* set a fixed width */
  height: 250px; /* set a fixed height */
  overflow: hidden; /* hide any overflow */
}
.post-container img {
  width: 100%; /* make the image fill the container horizontally */
  height: 100%; /* make the image fill the container vertically */
  object-fit: cover; /* ensure the image maintains aspect ratio and fills the container */
  object-position: center center; /* center the image within the container */
}

/*End for Blogs*/

/* Home */
/* #home {
  background: rgb(0, 101, 219);
  background: linear-gradient(90deg, rgb(0, 101, 219) 50%, rgba(0, 212, 255, 1));
  min-height: 500px;
} */
/* Card */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
.card {
  transition: all 0.5s ease;
}
.card:hover{
  cusor: pointer;
  box-shadow: 0.5rem 1rem rgba(0,0,0,.15);
}
/* Footer */
#footer {
  background: rgb(0, 101, 219);
  background: linear-gradient(90deg, #001D23 40%, #024654);
}

.logo {
  width: 200px;
  margin: 10px;
}

@media (max-width: 991.98px) {
  .logo{
    display: none;
  }
  .logo-small{
    width: 100px;
  }
}
.btn-outline-warning {
  font-weight: 400 !important;
}
.btn-outline-primary {
  color: #FF6F0F !important;
  border-color: #FF6F0F !important;
}
.btn-outline-primary:hover{
  color: #F8F8F9 !important;
  background-color: #FF6F0F!important;
  box-shadow: #001D23;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.btn-primary:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* From Template */
/********** Template CSS **********/
.img-fluid{
  width: 100%;
}
:root {
  --primary: #FF6F0F;
  --secondary: #FFF0E6;
  --light: #F8F8F9;
  --dark: #001D23;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-outline-primary:hover {
  color: #FFFFFF;
}

.btn.btn-primary:hover {
  color: var(--primary);
  background: transparent;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}


/*** Navbar ***/
.fixed-top {
  transition: .5s;
}

.top-bar {
  height: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, .07);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #024654 !important;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav {
      margin-top: 10px;
      border-top: 1px solid rgba(0, 0, 0, .07);
      background: var(--dark);
  }

  .navbar .navbar-nav .nav-link {
      padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      visibility: hidden;
      top: 100%;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      transition: .5s;
      opacity: 0;
  }

  .navbar .nav-item:hover .dropdown-menu {
      transform: rotateX(0deg);
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.carousel-caption {
  left: 0% !important;
  right: 0% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 29, 35, .5);
  z-index: 1;
  width: 100%;
  height: 100%;
  font-family: 'Saira';
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--dark);
  border: 12px solid var(--dark);
  border-radius: 3rem;
}
@media (max-width: 768px) {
  #header-carousel .carousel-item {
      position: relative;
      min-height: 650px;
  }

  #header-carousel .carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
}

.page-header {
  padding-top: 12rem;
  padding-bottom: 6rem;
  background: linear-gradient(rgba(0, 29, 35, .8), rgba(0, 29, 35, .8)), url("../public/assets/img1.jpg") center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
  color: #999999;
}


/*** Causes ***/
.causes-item .progress {
  height: 5px;
  border-radius: 0;
  overflow: visible;
}

.causes-item .progress .progress-bar {
  position: relative;
  overflow: visible;
  width: 0px;
  border-radius: 0;
  transition: 5s;
}

.causes-item .progress .progress-bar span {
  position: absolute;
  top: -7px;
  right: 0;
  width: 40px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background: var(--primary);
  color: #FFFFFF;
}

.causes-item .causes-overlay {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  overflow: hidden;
  opacity: 0;
  transition: .5s;
}

.causes-item:hover .causes-overlay {
  height: 100%;
  opacity: 1;
}


/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .06);
}


/*** Donate ***/
.donate {
  background: rgba(0, 29, 35, .8);
}

.btn-group .btn-light:hover,
.btn-group input[type="radio"]:checked+label {
  color: var(--primary);
  border-color: var(--primary);
}


/*** Team ***/
.team-item img {
  position: relative;
  top: 0;
  transition: .5s;
}

.team-item:hover img {
  top: -30px;
}

.team-item .team-text {
  position: relative;
  height: 100px;
  transition: .5s;
}

.team-item:hover .team-text {
  margin-top: -60px;
  height: 160px;
}

.team-item .team-text .team-social {
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-text .team-social {
  opacity: 1;
}

.team-item .team-social .btn {
  display: inline-flex;
  color: var(--primary);
  background: #FFFFFF;
  border-radius: 40px;
}

.team-item .team-social .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}


/*** Testimonial ***/
.testimonial-carousel::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.testimonial-carousel::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

@media (min-width: 768px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
      width: 200px;
  }
}

@media (min-width: 992px) {
  .testimonial-carousel::before,
  .testimonial-carousel::after {
      width: 300px;
  }
}

.testimonial-carousel .owl-item .testimonial-text {
  background: var(--light);
  transform: scale(.8);
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary);
  transform: scale(1);
}

.testimonial-carousel .owl-item .testimonial-text *,
.testimonial-carousel .owl-item .testimonial-item img {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item img {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 350px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: .5s;
  z-index: 1;
}

.testimonial-carousel:hover .owl-nav {
  width: 300px;
  opacity: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  color: var(--primary);
  font-size: 45px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  color: var(--dark);
}


/*** Footer ***/

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: rgba(255,255,255,0.5);
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: rgba(255,255,255,0.5);
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: rgba(255,255,255,0.5);
  border: 1px solid rgba(255,255,255,0.5);
}

.footer .btn.btn-square:hover {
  color: var(--secondary);
  border-color: var(--light);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--secondary);
}

.footer .copyright a:hover {
  color: var(--primary);
}
/* End from Template */

/* Department Detail image style */
.department-detail img {
  width: 100%;
}
