@font-face {
    font-family: 'Inter';
    src: url('../public/assets/fonts/Inter-Regular.ttf') format('truetype'),
         url('../public/assets/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Saira';
    src: url('../public/assets/fonts/Saira-Regular.ttf') format('truetype'),
         url('../public/assets/fonts/Saira-SemiBold.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}




.btn, h1, h2, h3, h4, h5 {
    font-family: "Saira";
    font-weight: 800 !important;
}
.btn-primary {
    background-color: #FF6F0F !important;
    color: #FFF !important;
    border:none !important;
}
#root, p {
    font-family: 'Inter';
}

.text-important {
    color: #FF6F0F;
}

.bg-important {
    background-color: #FFD7BC !important;
}
.th-bg-primary {
    background-color: #FF6F0F;
}
.th-border-primary {
    border-color: #FF6F0F !important;
    background-color: #FF6F0F;
}
@media(min-width: 601px) {
    .small-title {
        display: none;
    }
}

/* Set content font-families */

.ql-font-inconsolata {
    font-family: "Inconsolata";
}

.ql-font-roboto {
    font-family: "Roboto";
}

.ql-font-mirza {
    font-family: "Mirza";
}

.ql-font-arial {
    font-family: "Arial";
}
